@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;300;400;700;900&display=swap');


.roboto-condensed {
 font-family: "Roboto Condensed", sans-serif;
 font-optical-sizing: auto;
 font-weight: 100;
 font-style: normal;
}


html, body {
 height: 100%;
}



@layer base {
 :root {
   --background: 227 56% 28%;
   --foreground: 28 19% 80%;


   --muted: 210 40% 96.1%;
   --muted-foreground: 215.4 16.3% 46.9%;


   --popover: 0 0% 100%;
   --popover-foreground: 222.2 47.4% 11.2%;


   --border: 28 19% 80%;
   --input: 28 19% 80%;


   --card: 0 0% 100%;
   --card-foreground: 222.2 47.4% 11.2%;


   --primary: 34 13% 79%;
   --input: 28 19% 80%;


   --secondary: 0 0% 100%;
   --secondary-foreground: 222.2 47.4% 11.2%;


   --accent: 0 0% 100%;
   --accent-foreground: 222.2 47.4% 11.2%;


   --destructive: 0 0% 100%;
   --destructive-foreground: 210 40% 98%;


   --ring: 215 20.2% 65.1%;


   --radius: 1.5rem;
 }


 .dark {
   --background: 32 49 115;
   --foreground: 213 31% 91%;


   --muted: 223 47% 11%;
   --muted-foreground: 215.4 16.3% 56.9%;


   --accent: 216 34% 17%;
   --accent-foreground: 210 40% 98%;


   --popover: 224 71% 4%;
   --popover-foreground: 215 20.2% 65.1%;


   --border: 216 34% 17%;
   --input: 216 34% 17%;


   --card: 224 71% 4%;
   --card-foreground: 213 31% 91%;


   --primary: 210 40% 98%;
   --primary-foreground: 222.2 47.4% 1.2%;


   --secondary: 222.2 47.4% 11.2%;
   --secondary-foreground: 210 40% 98%;


   --destructive: 0 63% 31%;
   --destructive-foreground: 210 40% 98%;


   --ring: 216 34% 17%;


   --radius: 0.5rem;
 }
}


@layer base {
 * {
   @apply border-border;
 }
 body {
   @apply bg-background text-foreground;
     font-feature-settings: "rlig" 1, "calt" 1;
 }
}




